<template>
  <v-layout row wrap class="my-12 mx-0" align-center justify-center>
    <v-flex xs12 class="mt-12">
      <div class="text-center mt-12">
        <div class="mt-11 mx-2 justify-center" style="display: grid">
          <h2
            class="font-weight-medium nightText"
            :class="
              this.$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'
            "
          >
            Ajankohtaista
          </h2>
          <v-divider style="border-color: #004b55" class="mx-12 mt-5" />
        </div>
      </div>
    </v-flex>
    <v-flex xs12>
      <div class="mt-10 justify-center" style="display: grid">
        <v-card
          :max-width="$vuetify.breakpoint.mdAndDown ? '90vw' : 850"
          class="text-center"
        >
          <v-img
            position="center center"
            class="ma-10"
            cover
            alt="kampanja"
            :src="`${publicPath}/kampanja.jpg`"
          />
          <!--<v-divider></v-divider>
          <v-card-text class="headline font-weight-medium">
            Nyt myös tarviketoimitukset ilman asennusta. Kysy tarjous!
          </v-card-text>-->
        </v-card>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "company",
  data: () => ({
    publicPath: process.env.VUE_APP_DEPLOY_URL || ""
  })
};
</script>
